<template>
  <div class="orders-table">
    <v-row no-gutters class="row-header hidden-sm-and-down">
      <v-col cols="2" class="hidden-sm-and-down">
        <strong>N° Ordine</strong>
      </v-col>
      <!-- <v-col cols="2" class="hidden-sm-and-down">
        <strong>Data</strong>
      </v-col> -->
      <v-col cols="7" md="5">
        <strong>Consegna/Ritiro</strong>
      </v-col>
      <v-col cols="3" md="2">
        <strong>Stato</strong>
      </v-col>
      <v-col cols="2">
        <strong>Totale</strong>
      </v-col>
      <v-col cols="1" md="1" class="hidden-sm-and-down"> </v-col>
    </v-row>

    <v-list>
      <v-list-item
        v-for="order in orders"
        :key="order.orderId"
        v-bind:to="'/profile/orders/' + order.orderId"
      >
        <!-- :class="{ zebra: index % 2 == 0 }" -->
        <v-row no-gutters align="center">
          <v-col cols="2" class="hidden-sm-and-down" order-md="0">
            <strong class="order-id">#{{ order.orderId }}</strong>
          </v-col>
          <!-- <v-col cols="2" class="hidden-sm-and-down">
            <span>
              {{ $dayjs(order.addDate).format("DD/MM/YYYY - HH:mm") }}
            </span>
          </v-col> -->

          <v-col cols="6" md="5" order-md="1" order="1">
            <span class="text-capitalize d-flex flex-column">
              <span class="d-block d-md-none">
                Ordine n.{{ order.orderId }}
              </span>
              <!-- <img
                class="address-image d-none d-lg-inline"
                :src="
                  $t(
                    'navbar.service.' +
                      order.shippingAddress.deliveryServiceId +
                      '.icon',
                    { color: 'color' }
                  )
                "
              /> -->
              <div class="date-order-info">
                <span class="address">
                  {{
                    $t(
                      "navbar.address." +
                        order.shippingAddress.addressTypeId +
                        ".format",
                      order.shippingAddress
                    )
                  }}
                </span>
                -
                <span class="timeslot">{{
                  $dayjs(
                    order.timeslot.date + " " + order.timeslot.beginTime,
                    "YYYY-MM-DD hh:mm:ss A"
                  ).format("ddd DD/MM/YYYY - HH:mm")
                }}</span>
              </div>
            </span>
          </v-col>
          <v-col cols="3" md="2" order-md="2" order="0">
            <OrderStatusBadge v-bind:order="order" />
          </v-col>
          <v-col cols="2" order-md="3" order="2">
            <strong> {{ $n(calculatedTotal(order), "currency") }}</strong>
          </v-col>
          <v-col
            cols="1"
            class="actions-order justify-end hidden-sm-and-down"
            order-md="4"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  large
                  icon
                  class="main-button primary"
                  v-if="order.isPayable"
                  v-bind:to="'/profile/orders/' + order.orderId"
                >
                  <v-icon>$creditcard</v-icon>
                </v-btn>
              </template>
              <span>Ritenta pagamento</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click.stop.prevent="editOrder(order.orderId)"
                  large
                  icon
                  class="main-button"
                  v-if="order.isEditable"
                >
                  <v-icon>$edit</v-icon>
                </v-btn>
              </template>
              <span>Modifica ordine</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  large
                  icon
                  class="secondary-icon-button ml-1"
                  @click.stop.prevent="deleteOrder(order.orderId)"
                  v-if="order.isDeletable"
                >
                  <v-icon>$delete</v-icon>
                </v-btn>
              </template>
              <span>Annulla ordine</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  large
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="main-button"
                  v-if="order.orderStatusId == 8 || order.orderStatusId == 10"
                  @click.stop.prevent="addAllToCart(order)"
                >
                  <v-icon>$cart</v-icon>
                </v-btn>
              </template>
              <span>Copia Ordine</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<style scoped>
.address-image {
  width: 30px;
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge";
// import OrderService from "~/service/orderService";
import { mapState, mapGetters } from "vuex";
export default {
  name: "OrdersTable",
  props: ["orders"],
  components: {
    OrderStatusBadge
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    timeslot() {
      return this.getItemByProductId(this.product.productId);
    },
    quantity() {
      return this.item ? this.item.quantity : 0;
    },
    canIRateOrder() {
      return this.order.orderStatusId === 8 || this.order.orderStatusId === 18;
    }
  },
  methods: {
    rateOrder(orderId) {
      this.$router.push("/order/" + orderId);
    },
    calculatedTotal(order) {
      if (order.grossTotal > order.giftTotal) {
        return order.grossTotal;
      }
      return order.giftTotal;
    },
    async deleteOrder(orderId) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: orderId
        })
      });
      if (res) {
        this.$emit("delete", orderId);
      }
    },
    editOrder(orderId) {
      this.$emit("editOrder", orderId);
    },
    addAllToCart(order) {
      this.$emit("addAllToCart", order);
    }
  },
  mounted() {}
};
</script>
