<template>
  <div class="generic-list scroll-container">
    <!-- <v-container class="fill-height"> -->
    <!-- <Bread ></Bread> -->

    <CategoryTitle :category="category" />

    <OrdersTable
      v-if="orders && orders.length > 0"
      :orders="orders"
      @delete="deleteOrder"
      @addAllToCart="addAllToCart"
      @editOrder="editOrder"
    ></OrdersTable>
    <v-pagination
      color="white"
      circle
      v-if="orderPager && orderPager.totPages > 1"
      :value="pageFilter"
      :page="orderPager.selPage"
      :length="orderPager.totPages ? orderPager.totPages : 0"
      :totalVisible="6"
      @next.stop.prevent="handlePageFilter"
      @prev="handlePageFilter"
      @input="handlePageFilter"
    ></v-pagination>

    <v-card
      light
      outlined
      flat
      v-if="orders && orders.length == 0"
      class="pa-2 text-center"
      style="width:100%;"
    >
      <v-card-title class="headline">Nessun ordine trovato</v-card-title>
    </v-card>
    <!-- </v-container> -->
    <v-spacer class="spacer-scroll"></v-spacer>
  </div>
</template>

<style scoped lang="scss"></style>

<script>
// @ is an alias to /src
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import OrdersTable from "@/components/orders/OrdersTable.vue";
import CustomConfirmDialog from "@/components/CustomConfirmDialog.vue";

import categoryMixin from "~/mixins/category";
import reload from "~/mixins/reload";

import AnalyticsService from "~/service/analyticsService";
import OrderService from "~/service/orderService";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Orders",
  data() {
    return { orders: {}, orderPager: {} };
  },
  mixins: [reload, categoryMixin],
  components: {
    CategoryTitle,
    OrdersTable
    // OrderStatusBadge
    // OrderListElement
  },

  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter"
    })
  },
  methods: {
    ...mapActions({
      addProductsFromOrder: "cart/addProductsFromOrder",
      loadCart: "cart/loadCart"
    }),
    reload() {
      OrderService.getOrders(
        this.pageFilter,
        process.env.VUE_APP_ORDERS_LIST_PAGE_SIZE,
        true
      ).then(data => {
        this.orders = data.orders;
        this.orderPager = data.page;
      });
    },
    async deleteOrder(orderId) {
      await OrderService.deleteOrder(orderId);
      this.reload();
      let order = await OrderService.getOrder(orderId);
      AnalyticsService.refund(order);
    },
    editOrder(orderId) {
      OrderService.editOrder(orderId).then(() => {
        this.loadCart();

        this.$router.push({
          name: "Checkout"
        });
        // this.fetchOrder(orderId);
        // this.orders = data.orders;
        // // this.page = data.page;
        // this.orderPager = data.page;
      });
    },
    async addAllToCart(order) {
      let customPathMessage = "message.CustomAddAllFromOrder.standard";
      if (order.isEditable) {
        customPathMessage = "message.CustomAddAllFromOrder.isEditable";
      } else if (order.isDeletable) {
        customPathMessage = "message.CustomAddAllFromOrder.isDeletable";
      } else if (
        new Date().getTime() < new Date(order.timeslot.date).getTime()
      ) {
        customPathMessage = "message.CustomAddAllFromOrder.isStillNotDelivered";
      }

      let res = await this.$dialog.show(CustomConfirmDialog, {
        waitForResult: true,
        path: customPathMessage,
        paramContent: [order.orderId],
        paramContent2: [order.orderId]
      });
      if (res) {
        await this.addProductsFromOrder(order.orderId);
      }
    },
    handlePageFilter(page) {
      this.$store.dispatch("category/setFilterPage", page);
      this.reload();
      this.$vuetify.goTo(0);
    }
  },
  created() {
    this.reload();
  }
};
</script>
